import React from 'react';
import Link from 'next/link';

import { LocaleSwitcher, useTranslations } from '@keymono/i18n';
import { BackgroundForceField, LogoKeymono } from '@keymono/design-system';

type AuthPagesLayoutsProps = {
  leftChildren: React.ReactNode;
  rightChildren: React.ReactNode;
  rightChildrenBackground?: React.ReactNode;
  backgroundClass?: string;
};

/**
 * -----------------------------------------------------------------------------
 * The base layout for structuring public auth pages like sign-up, registration
 * and login.
 * TODO: ADD SEOMetaData
 */
export function AuthPagesLayout({
  leftChildren,
  rightChildren,
  rightChildrenBackground,
  backgroundClass,
}: AuthPagesLayoutsProps) {
  const tCommon = useTranslations('Common');

  return (
    <div className="flex min-h-full flex-col bg-white sm:flex-row">
      <div
        className="
            z-50 order-2 mx-4 mt-80 flex flex-1 flex-col justify-center rounded-t-xl
            bg-white/95 px-4 py-12 transition-all duration-500 ease-in
            sm:order-1 sm:mt-0 sm:px-6 lg:px-32
          "
      >
        <div className="mx-auto w-full max-w-sm">{leftChildren}</div>
      </div>
      <div
        className="
            absolute z-10 order-1 flex h-1/2 w-full flex-1 flex-col justify-center
            overflow-hidden bg-red-50 px-4 py-12 transition-all duration-500
            sm:relative sm:order-2  sm:h-screen sm:px-6 lg:px-32
          "
      >
        <Link
          href="/"
          aria-label={tCommon('logoAriaLabel')}
          title={tCommon('logoAriaLabel')}
          className="absolute top-8 inline-flex w-auto sm:hidden"
        >
          <LogoKeymono />
        </Link>
        {rightChildrenBackground || (
          <BackgroundForceField
            className={`
                absolute left-0 top-0 w-full transition-all duration-500
                ${backgroundClass}
              `}
          />
        )}
        <div className="mx-auto flex w-full max-w-sm">{rightChildren}</div>
      </div>
      <LocaleSwitcher className="is-floating" />
    </div>
  );
}
