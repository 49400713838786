import { useState } from 'react';
import type { GetStaticPropsContext } from 'next';
import { useRouter } from 'next/router';

import { useTranslations } from '@keymono/i18n';
import { SecurityPasswordLockAnimation } from '@keymono/design-system';

import { loadPageTranslations } from '@translations';
import { AuthPagesLayout } from '@layouts/AuthPagesLayout';
import {
  DynamicActiveAccountsLoginForm,
  DynamicLoginForm,
} from '@features/user-accounts';
import Head from 'next/head';

export async function getStaticProps({ locale = 'en' }: GetStaticPropsContext) {
  const { Login, Common } = await loadPageTranslations({ locale });

  return {
    props: { messages: { Login, Common } },
  };
}

/* TODO: Remove this commented out code about isLoginFormVisible,handleToggleLogin
      and onShowActiveAccounts once it`s reviewed and approved.
     -Code has been commented out because in the fix it is nolonger required to
      show the activeSessions on the login screen instead the user is redirected
      automatically to activeSessions screen using the URL parameters.
  */

/**
 * -----------------------------------------------------------------------------
 * The placeholder Login screen
 * TODO: ADD SEOMetaData
 */
export default function LoginPage() {
  const t = useTranslations('Login');

  // const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  // const handleToggleLogin = () => {
  //   setIsLoginFormVisible(() => !isLoginFormVisible);
  // };

  const handleUpdateFormIsSubmitting = (status: boolean) => {
    setIsFormSubmitting(() => status);
  };

  const { query } = useRouter();

  return (
    <>
      <Head>
        <title>{t('browserTitle')}</title>
      </Head>
      <AuthPagesLayout
        leftChildren={
          query.showActiveSessions === 'true' ? (
            <div>
              <DynamicActiveAccountsLoginForm />
            </div>
          ) : (
            <DynamicLoginForm
              iSubmitting={isFormSubmitting}
              // onShowActiveAccounts={handleToggleLogin}
              onUpdateIsSubmitting={handleUpdateFormIsSubmitting}
            />
          )
        }
        backgroundClass="rotate-90"
        rightChildren={
          isFormSubmitting ? (
            <SecurityPasswordLockAnimation key="loading" autoplay />
          ) : (
            <SecurityPasswordLockAnimation key="non-loading" autoplay={false} />
          )
        }
      />
    </>
  );
}
